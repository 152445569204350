import request from '@/services/request'
import apis from '@/services/apis'

export const getAvailableBarges = (parmas) => {
  return request.get({
    url: `${apis.getAvailableBarges}`,
    params: parmas
  })
}
export const getJobInfoByOrderId = (orderId) => {
  return request.get({
    url: `${apis.getJobInfoByOrder}?orderId=${orderId}`
  })
}

export const getAuditTrailsList = (jobId, pageSize = 20, pageNumber = 0) => {
  return request.get({
    url: `/ebdn/jobs/${jobId}/audit_trails?pageSize=${pageSize}&pageNumber=${pageNumber}`
  })
}

export const getJobFiles = (jobId) => {
  return request.get({
    url: `/ebdn/jobs/${jobId}/files`
  })
}

export const getJobReceipients = (jobId) => {
  return request.get({
    url: `/ebdn/jobs/${jobId}/recipients`
  })
}

export const saveJobReceipients = (jobId, payload) => {
  return request.post({
    url: `/ebdn/jobs/${jobId}/recipients`,
    data: payload
  })
}

export const getRecipients = (jobId) => {
  return request.get({
    url: `/ebdn/jobs/${jobId}/recipient`
  })
}

export const addRecipients = (jobId, payload) => {
  return request.post({
    url: `/ebdn/jobs/${jobId}/recipient`,
    data: payload
  })
}

export const shareEbdnEmail = (jobId, payload) => {
  return request.put({
    url: `/ebdn/jobs/${jobId}/shareEbdnEmail`,
    data: payload
  })
}
