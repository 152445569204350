<template>
	<el-select
    popper-class="custom-ship-selector"
    v-model="schedulFormData.bargeName"
    style="width: 100%"
    :disabled='disabled'
    @change="$emit('handleChangeBarge',$event)"
    @visible-change="$emit('visibleChange',$event)"
    :loading="availableBargesLoading"
    placeholder="Select"
  >
    <span
      style="
        display: inline-block;
        width: 200px;
        font-size: 14px;
        font-weight: 500;
        margin-left: 20px;
        margin-bottom: 10px;
      "
      >Name</span
    >
    <span
      style="
        display: inline-block;
        width: 100px;
        font-size: 14px;
        font-weight: 500;
      "
      >IMO</span
    >
    <span style="display: inline-block; font-size: 14px; font-weight: 500"
      >SB No.</span
    >
    <el-option
      v-for="item in availableBargesOptions"
      :key="item.id"
      :label="item.shipName"
      :value="item.id"
    >
      <span style="display: inline-block; width: 200px; font-size: 12px"
        >{{ item.shipName }}
      </span>
      <span style="display: inline-block; width: 100px; font-size: 12px">{{
        item.imo
      }}</span>
      <span style="display: inline-block; font-size: 12px">{{
        item.licenceNo
      }}</span>
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'BargeOrVesselSelector',
  props: {
    disabled:Boolean,
    schedulFormData: Object,
    availableBargesLoading: Boolean,
    availableBargesOptions: Array,
  }
}

</script>
